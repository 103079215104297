<template>
	<div class="all">
		<div class="title">
			<div>各省市项目情况汇总</div>
		</div>
		<div class="table">
			<dv-scroll-board :config="config" style="width: 90vw; height: 48vh; overflow: hidden; margin-top: -0vh;"
				@click="bgqh" />
		</div>
	</div>
</template>

<script>
	import {
		getSpread
	} from "@/api/index/index";
	export default {
		name: "mapindex",
		components: {},
		data: () => {
			return {
				count: 0,
				drop_line: 0,
				online: 0,
				loading: true,
				list: [],
				numarr: [],
				config: {},
				config1: {},
				option: "",
				myChart: "",
			};
		},
		created() {},
		mounted() {
			// this.chinaEcharts()
			this.getData();
		},
		computed: {},

		methods: {
			converStr(str) {
				//给数字字符串添加逗号分隔符
				if (/\./.test(str)) {
					return str
						.replace(/\d(?=(\d{1})+\.)/g, "$&,")
						.split("")
						.reverse()
						.join("")
						.replace(/\d(?=(\d{1})+\.)/g, "$&,")
						.split("")
						.reverse()
						.join("");
				} else {
					return str.replace(/\d(?=(\d{1})+$)/g, "$&,");
				}
			},
			getData() {
				getSpread({})
					.then((res) => {
						this.loading = false;
						this.online = res.online;
						this.drop_line = res.drop_line;
						this.list = res.product_type_num;
						//  将总数分割为数组
						let strNumber = res.count.toString();
						this.numarr = this.converStr(strNumber).split(",");
						this.config = {
							header: ["省份", "热风炉", "加热炉", "燃气炉", "CFB", "煤粉炉", "水泥生产线"],
							data: res.province,
							// index: true,//序号
							// columnWidth: [50],
							align: ['center'],
							rowNum: 12,
							oddRowBGC: "rgba(0,0,0,0)",
							evenRowBGC: "rgba(255,255,255,0)",
							columnWidth: [80],
						};
						console.log(res.province)
						if (res.province <= 11) {
							this.config.waitTime = 1000000000;
						}
						
					})
					.catch((err) => {
						this.loading = false;
						this.$message({
							message: "数据获取失败",
							type: "warning",
						});
					});
			},
			// 动图绘制
			drawLine() {
				this.$echarts.registerMap("china", china);
				let myChart = this.$echarts.init(document.getElementById("mapMain"));
				this.myChart = myChart;
				let that = this
				// 点击事件
				myChart.on('click', function(params) {

					if (params.value) {
						that.$router.push({
							path: '/cityMap',
							query: {
								cityName: params.name,
								cityData: JSON.stringify(params.value)
							}
						})
					} else {
						let mapArr = myChart.convertFromPixel('geo', [params.event.offsetX, params.event.offsetY])
						that.$router.push({
							path: '/cityMap',
							query: {
								cityName: params.name,
								cityData: JSON.stringify(mapArr)
							}
						})
					}
				});
				// 绘制图表
				myChart.setOption(this.option);
				window.addEventListener("resize", function() {
					myChart.resize();
				});
			},
			bgqh(row) {
				console.log(row);
			},
			// 打开更多各省市项目情况汇总
			toSummary() {
				this.$router.push({
					path: '/Solutionsum'
				})
			},
			// 打开通讯总览
			toOtherpage() {
				this.$router.push({
					path: "/userotherPage"
				});
			},
			// 打开CEI页面
			toCeipage() {
				this.$router.push({
					path: "/CeiPage"
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.all {
		width: 100vw;
		margin-left: -2.5vw;
		height: 92vh;
		background-color: #132735;

		.title {
			text-align: center;
			font-family: PingFang-SC-Regular;
			font-size: 1.88vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vw;
			letter-spacing: 0vw;
			color: #ffffff;
		}

		.table {
			width: 90vw;
			height: 80vh;
			overflow: hidden;
			margin-left: 5vw;
			margin-top: 2vh;
			border: #168db2 1px solid;
		}
	}
</style>
